import React from 'react';
import Layout from '../../components/organisms/Layout/Layout';
import PageBanner from '../../components/atoms/PageBanner/PageBanner';
import GiftCertificatesTab from '../../components/organisms/GiftCertificatesTab/GiftCertificatesTab';
import PurchaseForm from '../../components/molecules/PurchaseForm/PurchaseForm';
import emptyImage from './empty.png';

const GiftcertificatesPage = () => {
  return (
    <Layout>
      <PageBanner
        breadcrumb="Gift Certificates"
        title="Gift Certificates"
        color="powder"
        imageSize="medium"
        imageSrc={emptyImage}
      >
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem illo
          inventore accusantium doloremque laudantium, aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt ipsam.
        </p>
      </PageBanner>

      <GiftCertificatesTab pageId={'purchase-gift-certificate'}>
        <PurchaseForm />
      </GiftCertificatesTab>
    </Layout>
  );
};

export default GiftcertificatesPage;
