import React, { useState, useContext } from 'react';
import { bcApi } from '../../../helpers/bigcommerce';
import CartContext from '../../../context/CartProvider';

import Button from '../../atoms/Button/Button';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import { validateEmail } from '../../../helpers/general';
import Dialog from '../../atoms/Dialog/Dialog';

import styles from './PurchaseForm.module.css';

const validForm = data => {
  if (!data.your_name) {
    return 'Your Name is required.';
  }
  if (!data.recipients_name) {
    return 'Recipients Name is required.';
  }
  if (!data.your_email) {
    return 'Your your_email is required.';
  }
  if (!validateEmail(data.your_email)) {
    return 'Invalid your email address.';
  }
  if (!data.recipients_email) {
    return 'Your recipients email is required.';
  }
  if (!validateEmail(data.recipients_email)) {
    return 'Invalid recipients email address.';
  }
  if (!data.amount) {
    return 'Amount is required.';
  }
  if (data.amount < 20 || data.amount > 200) {
    return 'You must enter a certificate amount between $20.00 and $200.00';
  }
};

const PurchaseForm = () => {
  const [values, setValues] = useState({
    your_name: '',
    recipients_name: '',
    your_email: '',
    recipients_email: '',
    amount: '',
    optional_message: ''
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [msg, setMessage] = useState({ error: '', success: '' });

  const onHandleChange = (id, value) => {
    let tempValues = Object.assign({}, values);
    tempValues[id] = value;
    setValues(tempValues);
  };

  const cart = useContext(CartContext);

  const onPreview = () => {};

  const onSubmit = () => {
    const validationMsg = validForm(values);
    if (validationMsg) {
      setMessage({ error: validationMsg });
      return;
    }
    const reqBody = {
      gift_certificates: [
        {
          name: `$${values.amount} Gift Certificate`,
          theme: 'General',
          amount: parseFloat(values.amount, 10),
          quantity: 1,
          sender: {
            name: values.your_name,
            email: values.your_email
          },
          recipient: {
            name: values.recipients_name,
            email: values.recipients_email
          },
          message: values.optional_message
        }
      ]
    };

    bcApi(`carts${typeof cart?.state?.cart?.cartId === 'undefined' ? '' : `/${cart?.state?.cart?.cartId}/items`}`, 'POST', reqBody).then(
      ({ response, status }) => {
        if (status === 404) {
          setMessage({
            error: 'Sorry, something went wrong. Try again later.'
          });
          setDialogOpen(true);
          setValues({
            your_name: '',
            recipients_name: '',
            your_email: '',
            recipients_email: '',
            amount: '',
            optional_message: ''
          });
        } else {
          setValues({
            your_name: '',
            recipients_name: '',
            your_email: '',
            recipients_email: '',
            amount: '',
            optional_message: ''
          });
          window.location.href = `/cart?cache=${Date.now()}`;
        }
      }
    );
  };

  return (
    <div className={styles.purchaseform}>
      {msg.error && <p className={styles.error}>{msg.error}</p>}
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="your_name"
          type="input"
          labelName="Your Name"
          value={values['your_name']}
          handleChange={onHandleChange}
        />

        <FormInputField
          id="your_email"
          type="input"
          labelName="Your Email"
          value={values['your_email']}
          handleChange={onHandleChange}
        />
      </div>
      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <FormInputField
          id="recipients_name"
          type="input"
          labelName="Recipients Name"
          value={values['recipients_name']}
          handleChange={onHandleChange}
        />
        <FormInputField
          id="recipients_email"
          type="input"
          labelName="Recipients Email"
          value={values['recipients_email']}
          handleChange={onHandleChange}
        />
      </div>

      <FormInputField
        id="amount"
        type="input"
        labelName="Amount"
        value={values['amount']}
        handleChange={onHandleChange}
      />

      <FormInputField
        id="optional_message"
        type="textarea"
        labelName="Optional Message"
        value={values['optional_message']}
        handleChange={onHandleChange}
      />

      <div
        className={`grid grid-50 ${styles.inputGridGap} ${styles.inputGridWrapp}`}
      >
        <Button type="span" level="secondary" onClick={() => onPreview()}>
          Preview
        </Button>
        <Button type="span" level="primary" onClick={() => onSubmit()}>
          Add Gift Certificate to Cart
        </Button>
      </div>

      <Dialog
        hideBtnCancel={true}
        size="sm"
        open={dialogOpen}
        dividers={false}
        hideBtnOk={true}
        onCancel={() => setDialogOpen(false)}
      >
        {msg.success && (
          <p className={`${styles.success} ${styles.dialogContent}`}>
            {msg.success}
          </p>
        )}
        {msg.error && (
          <p className={`${styles.error} ${styles.dialogContent}`}>
            {msg.error}
          </p>
        )}
      </Dialog>
    </div>
  );
};

export default PurchaseForm;
